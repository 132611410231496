<template>
    <v-row no-gutters>
        <v-col cols="12">
            <!-- <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'user-dashboard' }">Dashboard</router-link> &gt;
                    <router-link :to="{ name: 'user-account-list' }">Accounts</router-link>
                    <template v-if="account">
                    &gt; <router-link :to="{ name: 'account-dashboard', params: { realm: this.$route.params.accountId } }">{{ account.name }}</router-link>
                    </template>
                </p>
                </v-col>
            </v-row> -->
            <!-- <v-row justify="center" class="py-5 mt-2">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <h1 class="text-h6 font-weight-light text-center">Dynamic DNS records</h1>
                </v-col>
            </v-row> -->
            <!-- <v-row justify="center" class="py-5">
                <v-col style="text-align: center" cols="12" sm="6">
                    <v-textarea :value="tinydnsconfig"></v-textarea>
                </v-col>
            </v-row> -->
            <v-row justify="center" class="py-5 px-10 mb-8">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">

                    <v-card tile elevation="4" class="pa-0" v-if="dynamicDnsRecordList">
                        <v-app-bar :color="primaryColor" dark flat dense>
                            <v-app-bar-title>Dynamic DNS records ({{ dynamicDnsRecordList.length }})</v-app-bar-title>
                            <v-spacer/>
                            <v-btn icon @click="createNewItemDialog = true">
                                <font-awesome-icon :icon="['fas', 'plus']" style="font-size: 20px;" fixed-width/>
                            </v-btn>
                        </v-app-bar>

                        <v-card-text v-if="dynamicDnsRecordList.length === 0">
                            <p>Dynamic DNS records help you reliably connect to your home or office even when
                                your ISP sometimes changes your IP address.</p>
                            <v-btn :style="primaryButtonStyle" elevation="2" @click="createNewItemDialog = true">
                                Add
                            </v-btn>
                        </v-card-text>

                        <v-list dense v-if="dynamicDnsRecordList.length > 0">
                            <v-list-item v-for="(item, idx) in dynamicDnsRecordList" :key="idx" @click="openEditItemDialog(item)">
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{ item.host }}.{{ item.domain }}
                                        <v-chip v-if="item.value === null" label small class="px-1 ml-2 green white--text">New</v-chip>
                                        <v-chip v-if="Array.isArray(item.value) && item.value.length === 1" label small class="px-1 ml-2 grey white--text">{{ ipaddr }}</v-chip>
                                        <template v-if="Array.isArray(item.value) && item.value.length > 1">
                                            <!-- TODO: do we need to limit how many are on the line, and offer a popup or something to see the full list of ipaddr? -->
                                            <v-chip v-for="(ipaddr, idx) in item.value" :key="idx" label small class="px-1 ml-2 grey white--text">{{ ipaddr }}</v-chip>
                                        </template>
                                        <!-- <v-chip v-if="item.status === 'new'" label small class="px-1 ml-2 yellow">{{ item.status }}</v-chip>
                                        <v-chip v-if="item.status === 'pending'" label small class="px-1 ml-2 blue lighten-4">{{ item.status }}</v-chip>
                                        <v-btn icon small color="purple darken-2" @click="openEditItemDialog(item)">
                                            <font-awesome-icon :icon="['fas', 'pencil-alt']" fixed-width/>
                                        </v-btn> -->
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-card>
                    <v-dialog v-model="createNewItemDialog" max-width="600">
                    <v-card tile elevation="4" class="pa-0" max-width="600">
                        <v-toolbar short flat color="white">
                            <v-toolbar-title class="purple--text text--darken-2">Add Dynamic DNS Host</v-toolbar-title>
                        </v-toolbar>
                        <v-card-text class="px-5">
                            <!-- TODO: retrieve current price for this feature for this account -->
                            <p>A fee of <span class="font-weight-bold">$1.25/mo</span> for this host will be added to your subscription.</p>
                        </v-card-text>
                        <v-alert type="error" border="left" dense v-if="!dynamicSharedDomainList" class="mx-5">
                            <template #prepend>
                                <font-awesome-icon fixed-width :icon="['fas', 'exclamation-triangle']" class="white--text text-body-1"/>
                            </template>
                            <p class="ml-4 pb-0 mb-0">This feature is not currently available. Please contact customer support.</p>
                        </v-alert>
                        <v-form @submit.prevent="createItem" @keyup.enter.native.prevent="createItem" class="px-2" v-if="dynamicSharedDomainList">
                            <v-select v-model="newItemDomain" :items="sharedDomainChoices" label="Domain"/>
                            <v-text-field v-model="newItemHost" label="Host name" hint="The server name, company name, or whatever you want to call it" ref="newItemHostInput"></v-text-field>
                        </v-form>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn elevation="4" class="purple white--text" @click="createItem" :disabled="!isNewItemFormComplete">
                                <span>Create</span>
                            </v-btn>
                            <v-btn text color="grey" @click="createNewItemDialog = false">
                                <span>Cancel</span>
                            </v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                    </v-dialog>
                    <v-dialog v-model="editItemDialog" max-width="600">
                    <v-card tile elevation="4" class="pa-0" max-width="600">
                        <v-toolbar short flat color="white">
                            <v-toolbar-title class="purple--text text--darken-2">Dynamic DNS Host</v-toolbar-title>
                        </v-toolbar>
                        <v-card-text class="px-5">
                            <p class="text-overline mb-0">Host</p>
                            <p class="mb-0">{{ editItemHost }}.{{ editItemDomain }}</p>

                            <p class="text-overline mb-0 mt-4">IP Address</p>
                            <p v-if="!Array.isArray(editItemValue) || editItemValue.length === 0">Use the Dynamic DNS client software to update this record.</p>
                            <v-list v-if="Array.isArray(editItemValue) && editItemValue.length > 1">
                                <v-list-item v-for="(ipaddr, idx) in editItemValue" :key="idx">
                                    <v-list-item-title>{{ ipaddr }}</v-list-item-title>
                                </v-list-item>
                            </v-list>

                            <p class="text-overline mb-0">Client software</p>
                            <p class="mb-0">Link to download client</p>
                            <p class="mb-0"><router-link :to="{ name: 'account-create-client-token', params: { accountId: this.$route.params.accountId }, query: { profile: 'dynamic_dns', domain: editItemDomain, host: editItemHost } }">Get an access token</router-link></p>

                            <p class="text-overline mb-0 mt-4">Other</p>
                            <p><router-link :to="{ name: 'account-delete-dynamic-dns-record', params: { accountId: this.$route.params.accountId }, query: { domain: this.editItemDomain, host: this.editItemHost } }">How to delete this Dynamic DNS record</router-link></p>

                            <!-- <v-form @submit.prevent="editItem" @keyup.enter.native.prevent="editItem" class="px-2">
                                <v-select v-model="editItemStatus" label="Status" :items="statusChoices"></v-select>
                            </v-form> -->

                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <!-- <v-btn elevation="4" class="purple white--text" @click="editItem" :disabled="!isEditItemFormComplete">
                                <span>Save</span>
                            </v-btn> -->
                            <v-btn text color="grey" @click="editItemDialog = false">
                                <span>Close</span>
                            </v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                    </v-dialog>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style scoped>

</style>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
    data: () => ({
        account: null,
        dynamicDnsRecordList: null,

        submitFormTimestamp: null,

        createNewItemDialog: false,
        newItemDomain: null,
        newItemHost: null,
        dynamicSharedDomainList: null,

        editItemDialog: false,
        editItemDomain: null,
        editItemHost: null,
        editItemValue: null,
    }),
    computed: {
        ...mapState({
            session: (state) => state.session,
        }),
        ...mapGetters({
            primaryColor: 'primaryColor',
            primaryButtonStyle: 'primaryButtonStyle',
        }),
        isNewItemFormComplete() {
            return typeof this.newItemDomain === 'string' && this.newItemDomain.length > 0 && typeof this.newItemHost === 'string' && this.newItemHost.length > 0;
        },
        isEditItemFormComplete() {
            return typeof this.editItemDomain === 'string' && this.editItemDomain.length > 0 && typeof this.editItemHost === 'string' && this.editItemHost.length > 0;
        },
        sharedDomainChoices() {
            if (!Array.isArray(this.dynamicSharedDomainList)) {
                return [];
            }
            return this.dynamicSharedDomainList.map((item) => ({ value: item.name, text: item.name }));
        },
    },
    watch: {
        createNewItemDialog(newValue, oldValue) {
            if (newValue && !oldValue) {
                if (this.sharedDomainChoices.length > 0) {
                    this.newItemDomain = this.sharedDomainChoices[0].value;
                }
                this.newItemHost = '';
                this.$nextTick(() => {
                    setTimeout(() => { this.activate('newItemHostInput'); }, 1);
                });
            }
        },
    },
    methods: {
        activate(ref) {
            const inputRef = Array.isArray(this.$refs[ref]) ? this.$refs[ref][0] : this.$refs[ref];
            if (inputRef) {
                // more than one way to do it:
                // 1. inputRef.focus();
                // 2. const inputElement = inputRef.$el.querySelector('input'); inputElement.focus();
                // 3. const inputElement = inputRef.$el.querySelector('input'); document.getElementById(inputElement.id).focus()
                inputRef.focus();
            }
        },
        async loadAccount() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadAccount: true });
                const response = await this.$client.account(this.$route.params.accountId).self.get();
                console.log(`account/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response) {
                    this.account = response;
                } else {
                    // TODO: redirect back to account list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load account', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadAccount: false });
            }
        },
        async loadDynamicDnsRecordList() {
            try {
                this.$store.commit('loading', { loadDynamicDnsRecordList: true });
                const response = await this.$client.account(this.$route.params.accountId).dynamicDnsRecord.search();
                if (response?.list) {
                    this.dynamicDnsRecordList = response.list;
                }
            } catch (err) {
                console.error('loadDynamicDnsRecordList failed', err);
            } finally {
                this.$store.commit('loading', { loadDynamicDnsRecordList: false });
            }
        },
        async loadDynamicSharedDomainList() {
            try {
                this.$store.commit('loading', { loadDynamicSharedDomainList: true });
                const response = await this.$client.account(this.$route.params.accountId).dynamicSharedDomain.search();
                if (response?.list) {
                    this.dynamicSharedDomainList = response.list;
                }
            } catch (err) {
                console.error('loadDynamicSharedDomainList failed', err);
            } finally {
                this.$store.commit('loading', { loadDynamicSharedDomainList: false });
            }
        },
        async createItem() {
            if (Number.isInteger(this.submitFormTimestamp) && this.submitFormTimestamp + 500 > Date.now()) {
                return;
            }
            this.submitFormTimestamp = Date.now();
            try {
                this.$store.commit('loading', { createItem: true });
                const item = {
                    domain: this.newItemDomain,
                    host_prefix: this.newItemHost,
                };
                const response = await this.$client.account(this.$route.params.accountId).dynamicDnsRecord.create(item);
                if (response?.isCreated && response.item) {
                    this.createNewItemDialog = false;
                    this.$bus.$emit('snackbar', { type: 'success', headline: 'OK' });
                    this.dynamicDnsRecordList.push(response.item);
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed' });
                }
            } catch (err) {
                console.error('createItem failed', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed' });
            } finally {
                this.$store.commit('loading', { createItem: false });
            }
        },
        async openEditItemDialog(item) {
            this.editItemDomain = item.domain;
            this.editItemHost = item.host;
            this.editItemValue = item.value;
            this.editItemDialog = true;
        },
        async editItem() {
            try {
                this.$store.commit('loading', { editItem: true });
                const response = await this.$client.main().dynamicSharedDomain.edit({ name: this.editItemHost }, { status: this.editItemStatus });
                if (response?.isEdited) {
                    this.createNewItemDialog = false;
                    this.$bus.$emit('snackbar', { type: 'success', headline: 'OK' });
                    const idx = this.dynamicDnsRecordList?.findIndex((item) => item.name === this.editItemHost);
                    if (Number.isInteger(idx) && idx > -1) {
                        this.dynamicDnsRecordList.splice(idx, 1, { name: this.editItemHost, status: this.editItemStatus });
                    } else {
                        this.loadDynamicDnsRecordList();
                    }
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed' });
                }
            } catch (err) {
                console.error('editItem failed', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed' });
            } finally {
                this.$store.commit('loading', { editItem: false });
            }
        },
    },
    mounted() {
        this.loadAccount();
        this.loadDynamicSharedDomainList();
        this.loadDynamicDnsRecordList();
    },
};
</script>
